<template lang="pug">
  .app-number-input
    input.field(
      ref="input"
      :class="{ changed, invalid, failed }"
      :disabled="disabled"
      :max="max"
      :min="min"
      :step="step"
      :value="inputValue"
      @input="handleEvent($event, 'input')"
      @change="handleEvent($event, 'change')"
      @wheel="$event.target.blur()"
    )
</template>

<script>
  // misc
  import IMask from "imask"
  import { toString } from "lodash-es"

  export default {
    props: {
      value: [Number, String],
      useDelimiter: {
        type: Boolean,
        default: false
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 999
      },
      step: {
        type: Number,
        default: 1
      },
      disabled: {
        type: Boolean,
        default: false
      },
      invalid: {
        type: Boolean,
        default: false
      },
      changed: {
        type: Boolean,
        default: false
      },
      failed: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      autoselect: {
        type: Boolean,
        default: false
      },
      allowNull: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        delimiterMask: IMask.createMask({ mask: Number, thousandsSeparator: "," })
      }
    },

    mounted() {
      if (this.autofocus) this.focusInput()
      if (this.autoselect) this.selectTextInInput()

      window.addEventListener("keydown", this.arrowsEventListener)
    },

    beforeDestroy() {
      window.removeEventListener("keydown", this.arrowsEventListener)
    },

    computed: {
      inputValue() {
        return this.useDelimiter ? this.formattedValue : this.value
      },

      formattedValue() {
        this.delimiterMask.resolve(toString(this.value))
        return this.delimiterMask.value
      },

      isWindows() {
        return navigator.platform.indexOf("Win") > -1
      }
    },

    methods: {
      arrowsEventListener({ target, key }) {
        if (this.$refs.input === target) {
          const applyValue = diff => {
            const newValue = this.getBorderValue(Number(target.value) + diff)
            this.$emit("input", newValue)
            this.$emit("change", newValue)
            setTimeout(() => (target.selectionStart = target.selectionEnd = target.value.length), 0)
          }

          switch (key) {
            case "ArrowUp":
              return applyValue(this.step)
            case "ArrowDown":
              return applyValue(-this.step)
          }
        }
      },

      handleEvent(event, eventName) {
        const validValue = this.getValidValueFromEvent(event)

        if (eventName === "change" || !this.isWindows) {
          event.currentTarget.value = validValue

          this.$emit(eventName, validValue)
          if (this.isWindows) this.$emit("input", validValue)
        }
      },

      getValidValueFromEvent(event) {
        let { value } = event.currentTarget

        // replace full-width digits to half-width common digits
        value = value.replace(/[\uff10-\uff19]/g, ch => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
        // remove all non-numeric letters
        value = value.replace(/[^0-9]/g, "")

        if (this.allowNull && value === "") return null

        return this.getBorderValue(value)
      },

      getBorderValue(value) {
        return Math.min(Math.max(this.min, Number(value)), this.max)
      },

      focusInput() {
        this.$refs.input.focus()
      },

      selectTextInInput() {
        this.$refs.input.select()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .app-number-input
    .changed
      color: $default-purple
      background: $default-purple-light

    .invalid
      +default-invalid-input

    .failed
      color: $alert-yellow-dark !important
      background: $alert-yellow
      border: 1px solid $alert-yellow-dark !important

    .field
      border: 1px solid $default-purple-light-line
      border-radius: 4px
      color: $link-color
      font-size: 0.8rem
      height: 24px
      text-align: center
      width: 30px

      &:disabled
        opacity: 0.3

        &:focus
          background: $default-purple-light
          color: $default-purple
</style>
